import { ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PipeTransform,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
  MatLegacyAutocompleteModule
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { capitalize, Direction, Filter, InFilter } from '@rims/lib';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DynamicPipe } from '../../pipes/dynamic.pipe';
import { DataService } from '../../services/data/data.service';

enum FieldNames {
  QUERY = 'QUERY',
  SELECTED_VALUES = 'SELECTED_VALUES',
  CONFIRM_OVERWRITE_ITEMS = 'CONFIRM_OVERWRITE_ITEMS'
}

@Component({
  selector: 'rims-autocomplete',
  templateUrl: './rims-autocomplete.component.html',
  styleUrls: ['./rims-autocomplete.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatLegacyProgressBarModule,
    MatLegacyFormFieldModule,
    MatLegacyChipsModule,
    NgFor,
    NgClass,
    MatLegacyTooltipModule,
    MatIconModule,
    MatLegacyAutocompleteModule,
    MatLegacyOptionModule,
    DynamicPipe
  ]
})
export class RimsAutocompleteComponent implements OnInit, AfterViewChecked, OnDestroy {
  /**
   * entity is both used as url as well as the identifier for the dynamic pipe
   */
  @Input()
  entity = '';

  /**
   * name and entity are the same in most cases, but sometimes they differ
   * (e.g. name: responsibilty,  entity: product_group_responsibility)
   */
  @Input()
  name = '';

  @Input()
  label = '';

  @Input()
  placeholder = '';

  currentPlaceholder = '';

  @Input()
  notFoundHint = '';

  @Input()
  duplicateHint = '';

  @Input()
  emptyHint = '';

  @Input()
  multiple = false;

  /**
   * If true the chip will be pipe transformed analogous to the options in the dropdown (default)
   * If false we will display the chip as chip[this.targetProperty].
   */
  @Input()
  pipeMultiple = true;

  /**
   * Enables pasting multiple entities at once
   */
  @Input()
  pasteMultiple = false;

  /**
   * The target property name of the selected option whose value will be used for the SELECTED_VALUE form control
   */
  @Input()
  target = 'id';

  /**
   * Alternative for when the target property for the /search request differs (currently the case for child groups)
   */
  @Input()
  searchTarget: string;

  @Input()
  expand = [];

  /**
   * @returns an array of filters
   * We use a function here (instead of static input values) because we want to access the (parents-)form values at the exact moment we fire the http call
   */
  @Input()
  getFilters: () => Filter[] = () => [];

  @Output()
  selected = new EventEmitter<any>();

  /**
   * If no formatPipe is provided we default to the Dynamic Pipe, which needs a token to define which underlying pipe to use
   */
  pipeToken: string = null;

  form: FormGroup;
  formatPipe: PipeTransform;
  fieldNames = FieldNames;
  filteredOptions: any[];
  loadingOptions = false;
  showMultipleHint = false;

  /**
   * All elements that have been selected/pasted successfully and are about to be added
   */
  selection: any = {};

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @ViewChild('query') query: ElementRef<HTMLInputElement>;

  chips: any[] = [];
  separatorKeysCodes: number[] = [ENTER, SEMICOLON];

  private destroy$ = new Subject<boolean>();

  constructor(
    private dataService: DataService,
    private rootFormGroup: FormGroupDirective,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control.get('autocomplete') as FormGroup;
    this.currentPlaceholder = this.placeholder;

    if (!this.formatPipe) {
      this.formatPipe = new DynamicPipe(this.injector);
      this.pipeToken = this.entity;
    }

    if (!this.searchTarget) {
      this.searchTarget = this.target;
    }

    this.processQueryChanges();

    if (this.multiple) {
      this.processInvalidValues();
    }
  }

  ngAfterViewChecked(): void {
    this.showMultipleHint = this.multiple && document.getElementsByClassName('warn').length === 0;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelect(sel: MatAutocompleteSelectedEvent) {
    if (this.multiple) {
      this.form.parent.get(FieldNames.CONFIRM_OVERWRITE_ITEMS)?.reset();
      this.query.nativeElement.value = '';
      this.form.get(FieldNames.QUERY).setValue('', { emitEvent: false });
      this.filteredOptions = [];
    } else {
      this.query.nativeElement.value = this.formatPipe.transform(sel.option.value, this.pipeToken);
      this.form
        .get(FieldNames.QUERY)
        .setValue(this.formatPipe.transform(sel.option.value, this.pipeToken), { emitEvent: false });
    }

    this.chips.push(sel.option.value);
    this.selection[sel.option.value[this.target]] = sel.option.value;
    this.selected.emit(this.selection);

    const currentlySelected = this.form.get(FieldNames.SELECTED_VALUES)?.value || [];
    this.form.get(FieldNames.SELECTED_VALUES).patchValue([...currentlySelected, sel.option.value[this.target]]);
    this.autocompleteTrigger.closePanel();
  }

  remove(option: any): void {
    this.form.get(FieldNames.QUERY).setValue('', { emitEvent: false });
    this.query.nativeElement.value = '';
    this.query.nativeElement.disabled = false;
    this.currentPlaceholder = this.placeholder;

    const control = this.form.get(FieldNames.SELECTED_VALUES);

    const index = this.chips.findIndex(chip => {
      if (option?.error) {
        return option.error === chip?.error;
      }

      return option[this.target] && option[this.target] === chip[this.target];
    });
    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    // remove chip from SELECTED_VALUES
    const value: any[] = control.value || [];
    const selectedIndex = value.indexOf(option[this.target]);
    if (selectedIndex >= 0) {
      value.splice(selectedIndex, 1);
      this.patchValueButSkipValidation(control, value);
    }

    delete this.selection[option[this.target]];
    this.selected.emit(this.selection);
  }

  /**
   *
   * @param event the paste event (triggered by the user interaction) from which we retrieve the query
   * @param customQueries a custom query for when the paste is triggered programatically by another method
   * @param markAsMismatch a boolean variable that will be added to each chip that is related its query's underlying item
   *
   * 'markAsMismatch' is a special use case for 'product_group_item'.
   *  Sometimes we want to allow adding items that have been marked as a 'mismatch' (properties between Item and BUDI differ)
   *  In that case we overwrite SELECTED_VALUES so that these items are no longer invalid,
   *  but we add the 'mismatch' boolean so that we can still show to the user which and how many items are affected
   */
  async paste(event: ClipboardEvent, customQueries?: string[], markAsMismatch = false) {
    if (!this.multiple || !this.pasteMultiple) return;
    if (!customQueries) {
      this.form.parent.get(FieldNames.CONFIRM_OVERWRITE_ITEMS)?.reset();
    }

    let queries = [];

    if (event) {
      event.preventDefault();
      queries = event.clipboardData
        .getData('Text')
        .trim()
        .replace(/(^;+)|(;+$)/, '') // remove any ';' at the start or end of query
        .split(/;|\r\n|\n\r|\n|\r/)
        .map(query => query.trim())
        .filter(query => query.length > 0);
    } else if (customQueries) {
      queries = customQueries;
    }

    if (queries.length === 0) return;

    const newValues = [];

    this.dataService
      .getAll(this.entity, this.expand, 0, queries.length, undefined, undefined, [
        ...this.getFilters(),
        new InFilter(this.searchTarget, queries.join(','))
      ])
      .pipe(
        take(1),
        map(response => response.results)
      )
      .subscribe(results => {
        results.forEach(element => {
          this.selection[element[this.target]] = element;
        });
        this.selected.emit(this.selection);

        if (!this.multiple && this.chips.length > 0) {
          this.query.nativeElement.disabled = true;
          this.currentPlaceholder = '';
          return;
        }

        const control = this.form.get(FieldNames.SELECTED_VALUES);
        const currentlySelected = control.value || [];

        queries.forEach(query => {
          const result = results.find(result => result[this.searchTarget] == query);
          const newChip = result
            ? { ...result, mismatch: markAsMismatch }
            : { error: query, color: 'error', tooltip: `${capitalize(this.name)} not found` };
          const chipIndex = this.chips.findIndex(chip => chip[this.searchTarget] === query || chip.error === query);
          const shouldAddValue = result && !currentlySelected.includes(result[this.target]);

          if (chipIndex < 0) {
            this.chips.push(newChip);
          } else if (result) {
            // overwrite already existing chip with new chip (because the mismatch property may be different)
            this.chips[chipIndex] = newChip;
          }

          if (shouldAddValue) {
            newValues.push(result[this.target]);
          }
        });

        const selectedValues = [...currentlySelected, ...newValues];
        control.patchValue(selectedValues);
        control.updateValueAndValidity();
      });
  }

  resetSingle() {
    if (!this.multiple) {
      this.chips = [];
      this.form.get(FieldNames.SELECTED_VALUES)?.reset();
    }
  }

  clear() {
    this.chips = [];
    this.form.get(FieldNames.QUERY).setValue('', { emitEvent: false });
    this.form.get(FieldNames.SELECTED_VALUES)?.reset();
    this.query.nativeElement.value = '';
    this.filteredOptions = [];
  }

  getMultipleHint() {
    return this.pasteMultiple
      ? 'Multiple entries are possible. You can also paste a list separated by semicolon.'
      : 'Multiple entries are possible';
  }

  /**
   * Listens to query changes and populates the autocomplete options accordingly.
   * If an option has already been added to the list of chips it will be filtered out.
   *
   */
  private processQueryChanges() {
    // We deliberately do not use "distinctUntilChanged" here,
    // because in some cases only the filters change while the query stays the same (e.g. add-nomenclature-to-item-dialog).
    this.form
      .get(FieldNames.QUERY)
      .valueChanges.pipe(
        filter(query => typeof query === 'string'),
        tap(query => {
          if (query.length === 0 && this.multiple) {
            this.filteredOptions = [];
          }
          this.loadingOptions = true;
        }),
        debounceTime(500),
        switchMap(query => {
          return this.dataService.getAll(
            this.entity,
            this.expand,
            0,
            20,
            { active: undefined, direction: Direction.BEGINS_WITH_ASC },
            undefined,
            this.getFilters(),
            query
          );
        }),
        map(response =>
          response.results.filter(option => {
            return !this.chips.map(chip => chip[this.target] || chip.error).includes(option[this.target]);
          })
        ),
        tap(result => {
          this.loadingOptions = false;
          if (!this.multiple || this.form.get(FieldNames.QUERY).value.length) {
            this.filteredOptions = result;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /**
   * Listens to invalid entries in SELECTED_VALUES and
   * - marks the underlying chip of each invalid value as an error
   * - removes all invalid values from SELECTED_VALUES
   */
  private processInvalidValues() {
    this.form
      .get(FieldNames.SELECTED_VALUES)
      .statusChanges.pipe(
        filter(status => status === 'INVALID'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const errors = this.form.get(FieldNames.SELECTED_VALUES).errors;
        const duplicates = errors?.duplicate || [];
        const customs = errors?.custom || [];
        const chipList = this.chips.map(chip => chip[this.target]);
        const invalidIds = [...duplicates, ...customs.map(custom => custom.id)];

        if (invalidIds.length > 0) {
          const invalidChips = [];
          duplicates.forEach(duplicate =>
            invalidChips.push({ error: duplicate, color: 'error', tooltip: this.duplicateHint })
          );
          customs.forEach(invalid =>
            invalidChips.push({
              error: invalid.id,
              color: invalid.mismatch ? 'warn' : 'error',
              tooltip: invalid.message,
              mismatch: invalid.mismatch
            })
          );

          invalidChips.forEach(invalid => {
            const index = chipList.indexOf(invalid.error);
            if (index >= 0) {
              if (this.pipeMultiple) {
                invalid.error = this.formatPipe.transform(this.chips[index], this.pipeToken);
              }
              this.chips[index] = invalid;
            }
          });

          const control = this.form.get(FieldNames.SELECTED_VALUES);
          const currentValues = control.value;
          const newValues = currentValues.filter(value => !invalidIds.includes(value));

          this.patchValueButSkipValidation(control, newValues);

          invalidIds.forEach(id => {
            delete this.selection[id];
          });
          this.selected.emit(this.selection);
        }
      });
  }

  /**
   * Patches the value but avoids (unnecessary) retriggering of validation (e.g. when removing an element)
   *
   */
  private patchValueButSkipValidation(control: AbstractControl, value: any) {
    const validators = control.asyncValidator;
    control.clearAsyncValidators();
    control.patchValue(value);
    control.addAsyncValidators(validators);
  }
}
