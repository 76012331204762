<form [formGroup]="form">
  <mat-progress-bar mode="indeterminate" *ngIf="form.pending || loadingOptions"></mat-progress-bar>
  <mat-form-field class="mb-2 w-100" appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList aria-label="Chip selection" [disabled]="form.get(fieldNames.QUERY).disabled">
      <ng-container *ngIf="multiple">
        <mat-chip [color]="chip?.color" [ngClass]="chip?.error ? 'strike' : null" *ngFor="let chip of chips" (removed)="remove(chip)" [matTooltip]="chip.tooltip">
          {{ chip.error ?? (pipeMultiple ? (chip | dynamicPipe : entity) : chip[target]) }}
          <button matChipRemove [attr.aria-label]="'remove ' + chip">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </ng-container>
      <input
        #autocompleteTrigger="matAutocompleteTrigger"
        #query
        [formControlName]="fieldNames.QUERY"
        [matAutocomplete]="auto"
        [placeholder]="currentPlaceholder"
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="true"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (paste)="paste($event)"
        (keyup.backspace)="resetSingle()"
        (keyup.delete)="resetSingle()"
      />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)" autoActiveFirstOption>
      <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{ option | dynamicPipe : entity }}
      </mat-option>
    </mat-autocomplete>

    <mat-hint>
      <span *ngIf="showMultipleHint">{{ getMultipleHint() }}</span>
      <span class="warn" *ngIf="emptyHint && !form.get(fieldNames.QUERY).value && form.get(fieldNames.QUERY).touched && form.get(fieldNames.SELECTED_VALUES)?.value?.length < 1">{{
        emptyHint
      }}</span>
      <span class="warn" *ngIf="notFoundHint && filteredOptions?.length === 0 && form.get(fieldNames.QUERY).value && !loadingOptions">{{ notFoundHint }}</span>
      <span class="warn" *ngIf="!multiple && duplicateHint && form.get(fieldNames.SELECTED_VALUES).hasError('duplicate')">{{ duplicateHint }}</span>
      <span class="warn" *ngIf="!multiple && form.get(fieldNames.SELECTED_VALUES).hasError('custom')">{{ form.get(fieldNames.SELECTED_VALUES).getError('custom').message }}</span>
    </mat-hint>
  </mat-form-field>
</form>
